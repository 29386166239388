import { Config } from './config';
import ApolloClient from 'apollo-client';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { AuthService } from './auth/auth.service';
import { HttpLink } from 'apollo-angular-link-http';
// import { HttpLink } from 'apollo-link-http';
import { WebSocketLink } from 'apollo-link-ws';
import { Injectable } from '@angular/core';
import { ApolloLink, from, split } from 'apollo-link';
import { getMainDefinition } from 'apollo-utilities';
import { onError } from 'apollo-link-error';

@Injectable({
  providedIn: 'root'
})
export class ApiClientFactory {
  uri = Config.adminApiBaseGraphqlUrl; // <-- add the URL of the GraphQL server here
  wsUri = Config.adminApiBaseWsUrl; // <-- add the URL of the GraphQL server here
  client: ApolloClient<any>;
  token: string;

  constructor(private httpClient: HttpClient, private authService: AuthService) {
    // do nothing
  }

  getClient() {
    const token = this.authService.getCurrentAuthToken();
    if (this.token === token) {
      console.log('ApiClientFactory(): using existing client');
      return this.client;
    }

    this.token = token;
    console.log('ApiClientFactory(): creating client with token');


    const errorHandler = onError(({ graphQLErrors, networkError, operation, forward, response }) => {
        if (graphQLErrors) {
          console.error('apollo errors', graphQLErrors);
        }
        if (networkError) {
          console.error('apollo network errors', networkError);
          if (!!networkError['error'] && !!networkError['error']['errors'] && networkError['error']['errors'][0]) {
            console.error('unwrapping apollo network errors');
            networkError.message = networkError['error']['errors'][0].message;
          }
        }
      }
    );

    // Create an http link:
    const httpLink = new HttpLink(this.httpClient).create({
      uri: this.uri,
      headers: new HttpHeaders({
        Authorization: this.token
      }),
      includeExtensions: true
    });

    const httpErrorLink = ApolloLink.from([
      errorHandler,
      httpLink,
    ]);

    // Create a WebSocket link:
    const wsLink = new WebSocketLink({
      uri: this.wsUri,
      options: {
        lazy: true,
        reconnect: true,
        connectionParams: {
          headers: {
            Authorization: this.token
          }
        },
      },
    });

    const wsErrorLink = ApolloLink.from([
      errorHandler,
      wsLink,
    ]);

    // using the ability to split links, you can send data to each link
    // depending on what kind of operation is being sent
    const link = split(
      // split based on operation type
      ({ query }) => {
        const definition = getMainDefinition(query);
        const isSubscription = definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
        console.log('isSubscription', isSubscription, definition);
        return isSubscription;
      },
      wsErrorLink,
      httpErrorLink
    );

    // const captureErrors = new ApolloLink((operation, forward) => {
    //   return forward(operation).map(response => {
    //     console.log('apollo response', response);
    //     return response;
    //   });
    // });

    this.client = new ApolloClient({
      link: link,
      cache: new InMemoryCache({ addTypename: false }),
      // the magic to ensure errors is passed back as part of result
      defaultOptions: {
        watchQuery: {
          errorPolicy: 'all'
        },
        mutate: {
          errorPolicy: 'all',
        },
        query: {
          errorPolicy: 'all'
        }
      }
    });

    return this.client;
  }
}
