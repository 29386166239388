import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { AppSandbox } from '../../app.sandbox';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'ngx-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(private authService: AuthService,
              private route: ActivatedRoute,
              private appSandbox: AppSandbox) { }

  ngOnInit() {
    const redirectTo = this.route.snapshot.queryParamMap.get('redirectTo');
    console.log('LoginComponent(): login', redirectTo);
    this.authService.login(redirectTo);
  }

}
