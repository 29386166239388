import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ImageEditorComponent } from './image-editor/image-editor.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PopoverModule } from 'ngx-bootstrap/popover';
import {
  NbChatModule,
  NbDatepickerModule,
  NbDialogModule,
  NbMenuModule,
  NbSidebarModule,
  NbToastrModule, NbWindowModule
  // NbWindowModule
} from '@nebular/theme';
import { CommonModule } from '@angular/common';
import { CoreModule } from './@core/core.module';
import { ThemeModule } from './@theme/theme.module';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AuthService } from './auth/auth.service';
import { CallbackComponent } from './callback/callback.component';
import { LoadingComponent } from './pages/loading/loading.component';
import { NgxsModule } from '@ngxs/store';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { ApplicationStateHandlers } from './app.state.handlers';
import { AppSandbox } from './app.sandbox';
import { LogoutComponent } from './pages/logout/logout.component';
import { environment } from '../environments/environment';
import { Config } from './config';
import { ApiClientFactory } from './api.client';
import { SortablejsModule } from 'ngx-sortablejs';
import { NgxPopperModule } from 'ngx-popper';
import {LoginComponent} from './pages/login/login.component';

@NgModule({
  declarations: [
    AppComponent,
    ImageEditorComponent,
    CallbackComponent,
    LoadingComponent,
    LoginComponent,
    LogoutComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,

    ThemeModule.forRoot(),

    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbDialogModule.forRoot(),
    NbWindowModule.forRoot(),
    NbToastrModule.forRoot(),
    NbChatModule.forRoot({
      messageGoogleMapKey: 'AIzaSyA_wNuCzia92MAmdLRzmqitRGvCF7wCZPY',
    }),
    CoreModule.forRoot(),
    // GraphQLModule,
    CommonModule,
    PopoverModule.forRoot(),

    NgxsModule.forRoot([
      ApplicationStateHandlers
    ], { developmentMode: !environment.production }),
    NgxsStoragePluginModule.forRoot({
      key: ['app']
    }),
    NgxsLoggerPluginModule.forRoot({ collapsed: true }),
    NgxsReduxDevtoolsPluginModule.forRoot({ disabled: environment.production }),
    SortablejsModule.forRoot({ animation: 150 }),
    NgxPopperModule.forRoot({})
  ],
  providers: [
    AuthService,
    AppSandbox,
    {
      provide: APP_INITIALIZER,
      useFactory: (http: HttpClient) => {
        return async () => {
          let config;

          if (!environment.production) {
            // dev mode, so check for a dev config file override
            try {
              config = await http.get('/config/config.dev.json').toPromise();
            } catch (err) {
              console.log('dev config file not found, using prod', err);
            }
          }
          if (!config) {
            config = await http.get('/config.json').toPromise();
          }

          console.log('loaded config', config);

          if (!!config && !!config.adminApiBaseUrl) {
            Config.adminApiBaseUrl = config.adminApiBaseUrl;
          }

          if (!!config && !!config.adminApiBaseGraphqlUrl) {
            Config.adminApiBaseGraphqlUrl = config.adminApiBaseGraphqlUrl;
          }

          if (!!config && !!config.adminApiBaseWsUrl) {
            Config.adminApiBaseWsUrl = config.adminApiBaseWsUrl;
          }

          if (!!config && !!config.contentApiBaseUrl) {
            Config.contentApiBaseUrl = config.contentApiBaseUrl;
          }

          if (!!config && !!config.auth0Hostname) {
            Config.auth0Hostname = config.auth0Hostname;
          }

          if (!!config && !!config.auth0ClientId) {
            Config.auth0ClientId = config.auth0ClientId;
          }

          if (!Config.auth0ClientId || !Config.auth0Hostname) {
            throw new Error('Please configure an auth client ID & hostname to continue');
          }

          if (!!config && !!config.storageBackendDefault) {
            Config.storageBackendDefault = config.storageBackendDefault;
          }

          console.log('using base URLs', Config.adminApiBaseGraphqlUrl, Config.adminApiBaseWsUrl, Config.contentApiBaseUrl);
          console.log('using auth0 details', Config.auth0Hostname, Config.auth0ClientId);
          console.log('using storage backend default', Config.storageBackendDefault);
        };
      },
      multi: true,
      deps: [HttpClient]
    },
    ApiClientFactory
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
