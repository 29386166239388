import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { library, dom } from '@fortawesome/fontawesome-svg-core';
import {
  faMap, faDirections, faArrowRight, faArrowLeft, faPlus, faMinus, faShoppingCart, faCalendarAlt, faStore,
  faNewspaper, faSearch, faCloud, faGlobeAmericas, faClock, faMapMarkerAlt, faHeart, faComment, faCheckCircle as fasCheckCircle,
  faWrench, faSyncAlt, faTrashAlt, faEdit, faSlidersH, faSortUp, faSortDown, faUpload, faSitemap, faProjectDiagram, faRedo, faSignOutAlt
} from '@fortawesome/free-solid-svg-icons';
import {
  faInstagram, faTwitter, faFacebook, faYoutube, faTripadvisor,
  faFoursquare, faYoutubeSquare
} from '@fortawesome/free-brands-svg-icons';
import { faCheckCircle as farCheckCircle } from '@fortawesome/free-regular-svg-icons';

// add each font-awesome icon you wish to reference here
library.add(
  faMap, faArrowRight, faArrowLeft, faPlus, faMinus, faShoppingCart, faCalendarAlt, faStore, faNewspaper, faSearch, faCloud,
  faGlobeAmericas, faClock, faMapMarkerAlt, faInstagram, faTwitter, faFacebook, faYoutube, faTripadvisor, faFoursquare,
  faDirections, faHeart, faComment, faYoutubeSquare, farCheckCircle, fasCheckCircle, faWrench, faSyncAlt, faTrashAlt, faEdit,
  faSlidersH, faSortUp, faSortDown, faUpload, faSitemap, faProjectDiagram, faRedo, faSignOutAlt
);
dom.watch();


if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
